import * as React from "react";
import { useState } from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { graphql } from "gatsby";
import { ContentfulCryptoExchange } from "../../common/types/contentful-models";
import { CryptoCurrency, CryptoExchangeComplexity } from "../../common/types/models";
import {
  getFeeBuyCryptoRatingInComparisonToOtherExchanges,
  getFeeSellCryptoRatingInComparisonToOtherExchanges,
  getNumberTradableCoinsRatingInComparisonToOtherExchanges,
  getSurchargeInPercentRatingInComparisonToOtherExchanges,
  isCoinTradable,
  mapExchangeRatingToStyleClass,
} from "../../common/helpers/crypto-exchange-helper";
import { Popover } from "@meikidd/react-tiny-popover";
import { getRandomStringFromList } from "../../common/helpers/list-helper";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { SiBitcoin } from "@react-icons/all-files/si/SiBitcoin";
import { FcCheckmark } from "@react-icons/all-files/fc/FcCheckmark";
import { FcCancel } from "@react-icons/all-files/fc/FcCancel";
import { SiEthereum } from "@react-icons/all-files/si/SiEthereum";
import { SiLitecoin } from "@react-icons/all-files/si/SiLitecoin";
import { SiRipple } from "@react-icons/all-files/si/SiRipple";
import Button from "../../components/UI-Elements/Button";
import Title from "@components/UI-Elements/Typography/Title";
// We need to use this since SSR is not supported yet be reactTinyPopover

export const allContentfulCryptoExchangesQuery = graphql`
  query ExchangeComparisonPageQuery {
    allContentfulCryptoExchange {
      nodes {
        name
        registerBonus
        exchangeFeatures
        complexity
        numberTradableCoins
        feeBuyCryptoInPercent
        feeSellCryptoInPercent
        surchargeInPercent
        registerBonusInEuro
        referralLinks
        countryUnicode
        feesLink
        homePage
        coinsLink
        tradableCoins
      }
    }
  }
`;

const ExchangeComparisonPage: React.FC<any> = ({ data }) => {
  const [isComplexityPopoverOpen, setIsComplexityPopoverOpen] = useState(false);
  const [isSurchargePopoverOpen, setIsSurchargePopoverOpen] = useState(false);

  const allContentfulCryptoExchanges: ContentfulCryptoExchange[] = data.allContentfulCryptoExchange.nodes.filter(
    (allContentfulCryptoExchange: ContentfulCryptoExchange) => !!allContentfulCryptoExchange.name
  );
  const numberCryptoExchanges = allContentfulCryptoExchanges.length;

  const complexity = (complexity?: CryptoExchangeComplexity) => {
    if (!complexity) {
      return '';
    }
    switch(complexity) {
      case CryptoExchangeComplexity.SIMPLE: return CryptoExchangeComplexity.SIMPLE
      case CryptoExchangeComplexity.COMPLEX: return CryptoExchangeComplexity.COMPLEX
    }
  }
  
  return (
    <Layout>
      <SEO keywords={["börsen vergleich"]} title="Börsen Vergleich" />
      <Title>Börsen Vergleich</Title>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 table-fixed">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className={"exchange-comparison-table-header-cell w-1/" + numberCryptoExchanges + 1}></th>
                    {allContentfulCryptoExchanges.map((contentfulCryptoExchange) => (
                      <th
                        scope="col"
                        className={"exchange-comparison-table-header-cell w-1/" + numberCryptoExchanges + 1}
                        key={contentfulCryptoExchange.name}
                      >
                        <a href={contentfulCryptoExchange.homePage}>
                          <div className="flex items-center">
                            {contentfulCryptoExchange.name}
                            <div className="pl-2 text-base">{getUnicodeFlagIcon(contentfulCryptoExchange.countryUnicode || '')}</div>
                          </div>
                        </a>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  <tr>
                    <td
                      className="exchange-comparison-table-description-cell"
                      onMouseEnter={() => setIsComplexityPopoverOpen(true)}
                      onMouseLeave={() => setIsComplexityPopoverOpen(false)}
                    >
                      <Popover
                        isOpen={isComplexityPopoverOpen}
                        positions={["top", "bottom", "left", "right"]} // preferred positions by priority
                        content={
                          <div className="text-sm popover">
                            Eine übersichtliche und einfach bedienbare
                            <br />
                            Anwendung ist weniger komplex.
                          </div>
                        }
                      >
                        <div></div>
                      </Popover>
                      Komplexität<span className="text-einfach-crypto-red">*</span>
                    </td>
                    {allContentfulCryptoExchanges.map((contentfulCryptoExchange) => (
                      <td key={"complexity-" + contentfulCryptoExchange.name} className="exchange-comparison-table-data-cell">
                        {complexity(contentfulCryptoExchange.complexity)}
                      </td>
                    ))}
                  </tr>
                  <tr className="exchange-comparison-table-summary-description-row">
                    <td className="exchange-comparison-table-summary-description-cell">Gebühren</td>
                    {allContentfulCryptoExchanges.map((contentfulCryptoExchange) => (
                      <td key={"fees-" + contentfulCryptoExchange.name} className={"exchange-comparison-table-summary-data-cell"}>
                        <a href={contentfulCryptoExchange.feesLink}>{contentfulCryptoExchange.name}</a>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="exchange-comparison-table-summary-details-description-cell">Kauf (BTC)</td>
                    {allContentfulCryptoExchanges.map((contentfulCryptoExchange) => (
                      <td
                        key={"feeBuyCryptoInPercent-" + contentfulCryptoExchange.name}
                        className={
                          "exchange-comparison-table-data-cell " +
                          mapExchangeRatingToStyleClass(
                            getFeeBuyCryptoRatingInComparisonToOtherExchanges(contentfulCryptoExchange, allContentfulCryptoExchanges)
                          )
                        }
                      >
                        {contentfulCryptoExchange.feeBuyCryptoInPercent + "%"}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="exchange-comparison-table-summary-details-description-cell">Verkauf (BTC)</td>
                    {allContentfulCryptoExchanges.map((contentfulCryptoExchange) => (
                      <td
                        key={"feeSellCryptoInPercent-" + contentfulCryptoExchange.name}
                        className={
                          "exchange-comparison-table-data-cell " +
                          mapExchangeRatingToStyleClass(
                            getFeeSellCryptoRatingInComparisonToOtherExchanges(contentfulCryptoExchange, allContentfulCryptoExchanges)
                          )
                        }
                      >
                        {contentfulCryptoExchange.feeSellCryptoInPercent + "%"}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td
                      className="exchange-comparison-table-summary-details-description-cell"
                      onMouseEnter={() => setIsSurchargePopoverOpen(true)}
                      onMouseLeave={() => setIsSurchargePopoverOpen(false)}
                    >
                      <Popover
                        isOpen={isSurchargePopoverOpen}
                        positions={["top", "bottom", "left", "right"]} // preferred positions by priority
                        content={
                          <div className="text-sm popover">
                            Als Preisaufschlag wird der
                            <br />
                            Wert bezeichnet, welcher
                            <br />
                            zusätzlich zu dem aktuellen
                            <br />
                            Kryptowährungpreis bezahlt
                            <br />
                            werden muss.
                          </div>
                        }
                      >
                        <div></div>
                      </Popover>
                      Preisaufschlag<span className="text-einfach-crypto-red">*</span>
                    </td>
                    {allContentfulCryptoExchanges.map((contentfulCryptoExchange) => (
                      <td
                        key={"surchargeInPercent-" + contentfulCryptoExchange.name}
                        className={
                          "exchange-comparison-table-data-cell " +
                          mapExchangeRatingToStyleClass(
                            getSurchargeInPercentRatingInComparisonToOtherExchanges(contentfulCryptoExchange, allContentfulCryptoExchanges)
                          )
                        }
                      >
                        {contentfulCryptoExchange.surchargeInPercent + "%"}
                      </td>
                    ))}
                  </tr>
                  <tr className="exchange-comparison-table-summary-description-row">
                    <td className="exchange-comparison-table-summary-description-cell">Handelbare Coins</td>
                    {allContentfulCryptoExchanges.map((contentfulCryptoExchange) => (
                      <td
                        key={"numberTradableCoins-" + contentfulCryptoExchange.name}
                        className={
                          "exchange-comparison-table-summary-data-cell " +
                          mapExchangeRatingToStyleClass(
                            getNumberTradableCoinsRatingInComparisonToOtherExchanges(contentfulCryptoExchange, allContentfulCryptoExchanges)
                          )
                        }
                      >
                        <a href={contentfulCryptoExchange.coinsLink}>{contentfulCryptoExchange.numberTradableCoins}</a>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="exchange-comparison-table-summary-details-description-cell">
                      <div className="flex items-center">
                        Bitcoin (BTC)
                        <div className="pl-2 text-lg">
                          <SiBitcoin />
                        </div>
                      </div>
                    </td>
                    {allContentfulCryptoExchanges.map((contentfulCryptoExchange) => (
                      <td key={"is-bitcoin-tradable-" + contentfulCryptoExchange.name} className={"exchange-comparison-table-data-cell"}>
                        <div className={"flex justify-center text-lg"}>
                          {isCoinTradable(CryptoCurrency.BTC, contentfulCryptoExchange.tradableCoins) ? <FcCheckmark /> : <FcCancel />}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="exchange-comparison-table-summary-details-description-cell">
                      <div className="flex items-center">
                        Ethereum (ETH)
                        <div className="pl-2 text-lg">
                          <SiEthereum />
                        </div>
                      </div>
                    </td>
                    {allContentfulCryptoExchanges.map((contentfulCryptoExchange) => (
                      <td key={"is-ethereum-tradable-" + contentfulCryptoExchange.name} className={"exchange-comparison-table-data-cell"}>
                        <div className={"flex justify-center text-lg"}>
                          {isCoinTradable(CryptoCurrency.ETH, contentfulCryptoExchange.tradableCoins) ? <FcCheckmark /> : <FcCancel />}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="exchange-comparison-table-summary-details-description-cell">
                      <div className="flex items-center">
                        Litecoin (LTC)
                        <div className="pl-2 text-lg">
                          <SiLitecoin />
                        </div>
                      </div>
                    </td>
                    {allContentfulCryptoExchanges.map((contentfulCryptoExchange) => (
                      <td key={"is-litecoin-tradable-" + contentfulCryptoExchange.name} className={"exchange-comparison-table-data-cell"}>
                        <div className={"flex justify-center text-lg"}>
                          {isCoinTradable(CryptoCurrency.LTC, contentfulCryptoExchange.tradableCoins) ? <FcCheckmark /> : <FcCancel />}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="exchange-comparison-table-summary-details-description-cell">
                      <div className="flex items-center">
                        Ripple (XRP)
                        <div className="pl-2 text-lg">
                          <SiRipple />
                        </div>
                      </div>
                    </td>
                    {allContentfulCryptoExchanges.map((contentfulCryptoExchange) => (
                      <td key={"is-ripple-tradable-" + contentfulCryptoExchange.name} className={"exchange-comparison-table-data-cell"}>
                        <div className={"flex justify-center text-lg"}>
                          {isCoinTradable(CryptoCurrency.XRP, contentfulCryptoExchange.tradableCoins) ? <FcCheckmark /> : <FcCancel />}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="exchange-comparison-table-description-cell">Anmeldebonus</td>
                    {allContentfulCryptoExchanges.map((contentfulCryptoExchange) => (
                      <td key={"registerBonus-" + contentfulCryptoExchange.name} className={"exchange-comparison-table-data-cell "}>
                        <Button href={getRandomStringFromList(contentfulCryptoExchange.referralLinks)}>Anmelden</Button>
                        <p className="mt-1">
                          {contentfulCryptoExchange.registerBonusInEuro
                            ? `Erhalte ${contentfulCryptoExchange.registerBonusInEuro} Euro`
                            : "."}
                        </p>
                      </td>
                    ))}
                  </tr>
                  {/*<tr>*/}
                  {/*    <td className="exchange-comparison-table-description-cell">*/}
                  {/*        EC-Bewertung*/}
                  {/*    </td>*/}
                  {/*    {allContentfulCryptoExchanges.map((contentfulCryptoExchange) => (*/}
                  {/*        <td key={'ec-rating-' + contentfulCryptoExchange.name}*/}
                  {/*            className={'exchange-comparison-table-data-cell '*/}
                  {/*            + mapExchangeRatingToStyleClass(*/}
                  {/*                getRegisterBonusInEuroRatingInComparisonToOtherExchanges(*/}
                  {/*                    contentfulCryptoExchange,*/}
                  {/*                    allContentfulCryptoExchanges))}>*/}
                  {/*            <a href={getRandomStringFromList(contentfulCryptoExchange.referralLinks)}>*/}
                  {/*                GUT*/}
                  {/*            </a>*/}
                  {/*        </td>*/}
                  {/*    ))}*/}
                  {/*</tr>*/}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ExchangeComparisonPage;
