/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ContentfulCryptoExchange } from "../types/contentful-models";
import { CryptoCurrency } from "../types/models";

export enum ExchangeRatingEnum {
  WORST = "WORST",
  MEDIUM = "MEDIUM",
  BEST = "BEST",
}

export enum ExchangeRatingBestOrderEnum {
  LOWEST,
  HIGHEST,
}

export function getFeeBuyCryptoRatingInComparisonToOtherExchanges(
  cryptoExchange: ContentfulCryptoExchange,
  allCryptoExchanges: ContentfulCryptoExchange[]
): ExchangeRatingEnum {
  return getCryptoExchangeRatingByProperty(cryptoExchange, allCryptoExchanges, "feeBuyCryptoInPercent", ExchangeRatingBestOrderEnum.LOWEST);
}

export function getFeeSellCryptoRatingInComparisonToOtherExchanges(
  cryptoExchange: ContentfulCryptoExchange,
  allCryptoExchanges: ContentfulCryptoExchange[]
): ExchangeRatingEnum {
  return getCryptoExchangeRatingByProperty(
    cryptoExchange,
    allCryptoExchanges,
    "feeSellCryptoInPercent",
    ExchangeRatingBestOrderEnum.LOWEST
  );
}

export function getNumberTradableCoinsRatingInComparisonToOtherExchanges(
  cryptoExchange: ContentfulCryptoExchange,
  allCryptoExchanges: ContentfulCryptoExchange[]
): ExchangeRatingEnum {
  return getCryptoExchangeRatingByProperty(cryptoExchange, allCryptoExchanges, "numberTradableCoins", ExchangeRatingBestOrderEnum.HIGHEST);
}

export function getSurchargeInPercentRatingInComparisonToOtherExchanges(
  cryptoExchange: ContentfulCryptoExchange,
  allCryptoExchanges: ContentfulCryptoExchange[]
): ExchangeRatingEnum {
  return getCryptoExchangeRatingByProperty(cryptoExchange, allCryptoExchanges, "surchargeInPercent", ExchangeRatingBestOrderEnum.LOWEST);
}

export function getRegisterBonusInEuroRatingInComparisonToOtherExchanges(
  cryptoExchange: ContentfulCryptoExchange,
  allCryptoExchanges: ContentfulCryptoExchange[]
): ExchangeRatingEnum {
  return getCryptoExchangeRatingByProperty(cryptoExchange, allCryptoExchanges, "registerBonusInEuro", ExchangeRatingBestOrderEnum.HIGHEST);
}

export function mapExchangeRatingToStyleClass(exchangeRating: ExchangeRatingEnum): string {
  switch (exchangeRating) {
    case ExchangeRatingEnum.BEST:
      return "exchange-comparison-table-best-value";
    case ExchangeRatingEnum.WORST:
      return "exchange-comparison-table-worst-value";
    default:
      return "";
  }
}

function getCryptoExchangeRatingByProperty(
  cryptoExchange: ContentfulCryptoExchange,
  allCryptoExchanges: ContentfulCryptoExchange[],
  ratingProperty: string,
  exchangeRatingBestOrder: ExchangeRatingBestOrderEnum
) {
  const allCryptoExchangesSorted = sortCryptoExchangesByProperty(allCryptoExchanges, ratingProperty, exchangeRatingBestOrder);
  //@ts-ignore
  const bestValue = allCryptoExchangesSorted[0][ratingProperty];
  //@ts-ignore
  const worstValue = allCryptoExchangesSorted[allCryptoExchanges.length - 1][ratingProperty];
  //@ts-ignore
  if (bestValue === cryptoExchange[ratingProperty]) {
    return ExchangeRatingEnum.BEST;
    //@ts-ignore
  } else if (worstValue === cryptoExchange[ratingProperty]) {
    return ExchangeRatingEnum.WORST;
  }
  return ExchangeRatingEnum.MEDIUM;
}

function sortCryptoExchangesByProperty(
  allCryptoExchanges: ContentfulCryptoExchange[],
  attribute: string,
  order: ExchangeRatingBestOrderEnum
): ContentfulCryptoExchange[] {
  const exchanges = allCryptoExchanges.map((cryptoExchanges) => Object.assign({}, cryptoExchanges));
  const sortedExchanges = exchanges.sort((exchange1, exchange2) =>
    //@ts-ignore
    exchange1[attribute] < exchange2[attribute] ? -1 : exchange1[attribute] > exchange2[attribute] ? 1 : 0
  );
  if (order === ExchangeRatingBestOrderEnum.HIGHEST) {
    return sortedExchanges.reverse();
  }
  return sortedExchanges;
}

export function isCoinTradable(cryptoCurrency: CryptoCurrency, tradableCoins?: CryptoCurrency[]) {
  if (!tradableCoins) return false;
  return tradableCoins.some((tradableCoin) => tradableCoin === cryptoCurrency);
}
